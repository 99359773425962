import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "state/api";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DatePicker from "react-datepicker";
import * as XLSX from "xlsx";

import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
} from "@mui/icons-material";
import Loader from "react-js-loader";
import "./carts.css";
import EditCartDialog from "./editDialog";

const Carts = () => {
  const { cartId } = useParams();
  const [loading, setLoading] = useState(false);
  const [carts, setCarts] = useState([]);
  const [filteredCarts, setFilteredCarts] = useState([]);
  const [users, setUsers] = useState([]);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingCartIds, setDeletingCartIds] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [selectedRows, setSelectedRows] = useState([]);

  // Edit cart data Dialog
  const [selectedcartData, setSelectedcartData] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedcartShippingAddress, SetSelectedcartShippingAddress] =
    useState(null);
  const [selectedcartUser, SetSelectedcartUser] = useState(null);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Africa/Cairo",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.getCarts();

        setCarts(response.cart);
        setUsers(response.users);
        setShippingAddresses(response.shippingAddress);

        if (cartId) {
          const filtered = response.cart.filter(
            (cart) => cart.id === parseInt(cartId)
          );
          setFilteredCarts(filtered);
        } else {
          setFilteredCarts(response.cart);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [cartId]);

  const handleDelete = (ids) => {
    setDeletingCartIds(ids);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      setLoading(true);
      setDeleteDialogOpen(false);
      if (deletingCartIds.length === 1) {
        await api.removeCart(deletingCartIds[0]);
      } else {
        await api.removeCarts({ CartIds: deletingCartIds });
      }
      const response = await api.getCarts();
      setCarts(response.cart);
      setFilteredCarts(response.cart);
      setAlert({
        open: true,
        message: "Cart(s) deleted successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting cart(s):", error);
      setAlert({
        open: true,
        message: "Failed to delete cart(s). Please try again.",
        severity: "error",
      });
    } finally {
      setDeletingCartIds([]);
      setLoading(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeletingCartIds([]);
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleAddressChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleResetFilters = () => {
    setSelectedUser("");
    setSelectedAddress("");
    setFilteredCarts(carts);
    setFromDate(null);
    setToDate(null);
  };

  const handleEditClick = (cartId) => {
    console.log(cartId);

    const cart = carts.find((cart) => cart.id === cartId);

    const shipping = shippingAddresses.find(
      (address) => address.id === cart.shipping_address_id
    );

    const user = users.find((user) => user.id === cart.user_id);

    SetSelectedcartShippingAddress(shipping);
    SetSelectedcartUser(user);
    setSelectedcartData(cart);
    setEditDialogOpen(true);
  };

  const handleExport = () => {
    const dataToExport =
      selectedRows.length > 0
        ? filteredCarts.filter((cart) => selectedRows.includes(cart.id))
        : filteredCarts;

    const columnNamesMapping = {
      id: "Cart ID",
      user: "User",
      shipping_address: "Shipping Address",
      subtotal: "Subtotal",
      shipping_fees: "Shipping Fees",
      promocode_id: "Promo Code ID",
      points: "Points",
      total_price: "Total Price",
      created_at: "Created At",
      updated_at: "Updated At",
    };

    const excelData = dataToExport.map((cart) => {
      const user = users.find((u) => u.id === cart.user_id);
      const shippingAddress = shippingAddresses.find(
        (sa) => sa.id === cart.shipping_address_id
      );

      const mappedData = {
        id: cart.id,
        user: user
          ? `${user.first_name} ${user.last_name} - ${user.phone}`
          : "",
        shipping_address: shippingAddress
          ? `${shippingAddress.first_name} ${shippingAddress.last_name} - ${shippingAddress.address} - ${shippingAddress.phone}`
          : "",
        subtotal: cart.subtotal,
        shipping_fees: cart.shipping_fees,
        promocode_id: cart.promocode_id,
        points: cart.points,
        total_price: cart.total_price,
        created_at: cart.created_at,
        updated_at: cart.updated_at,
      };

      const mappedcart = {};
      Object.keys(mappedData).forEach((key) => {
        mappedcart[columnNamesMapping[key]] = mappedData[key];
      });

      return mappedcart;
    });

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Carts");
    XLSX.writeFile(wb, "carts.xlsx");
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "userDetails",
      headerName: "User",
      width: 150,
      renderCell: (params) => {
        const user = users.find((user) => user.id === params.row.user_id);
        return user
          ? `${user.first_name} ${user.last_name} - ${user.phone}`
          : "";
      },
    },
    {
      field: "shippingDetails",
      headerName: "Shipping Address",
      width: 400,
      renderCell: (params) => {
        const shipping = shippingAddresses.find(
          (address) => address.id === params.row.shipping_address_id
        );
        return shipping
          ? `${shipping.first_name} ${shipping.last_name} - ${shipping.phone} - ${shipping.email}`
          : "";
      },
    },
    { field: "subtotal", headerName: "Subtotal", width: 120 },
    { field: "shipping_fees", headerName: "Shipping Fees", width: 100 },
    { field: "promocode_id", headerName: "Promocode ID", width: 100 },
    { field: "points", headerName: "Points", width: 120 },
    { field: "total_price", headerName: "Total Price", width: 120 },
    { field: "created_at", headerName: "Created At", width: 200 },
    { field: "updated_at", headerName: "Updated At", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            color="secondary"
            onClick={() => handleEditClick(params.row.id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleDelete([params.row.id])}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = filteredCarts
    .filter((cart) => {
      const userMatch = selectedUser ? cart.user_id === selectedUser : true;
      const addressMatch = selectedAddress
        ? cart.shipping_address_id === selectedAddress
        : true;
      const startDateMatch = fromDate
        ? new Date(cart.created_at) >= fromDate
        : true;
      const endDateMatch = toDate ? new Date(cart.created_at) <= toDate : true;
      return userMatch && addressMatch && startDateMatch && endDateMatch;
    })
    .map((cart) => ({
      id: cart.id,
      user_id: cart.user_id,
      shipping_address_id: cart.shipping_address_id,
      subtotal: cart.subtotal,
      shipping_fees: cart.shipping_fees,
      promocode_id: cart.promocode_id,
      points: cart.points,
      total_price: cart.total_price,
      created_at: formatDate(cart.created_at),
      updated_at: formatDate(cart.updated_at),
    }));

  return (
    <div className="container">
      <Box
        sx={{ display: "flex", alignItems: "center", marginBottom: 2, gap: 2 }}
      >
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="user-select-label">User</InputLabel>
          <Select
            labelId="user-select-label"
            id="user-select"
            value={selectedUser}
            label="User"
            onChange={handleUserChange}
          >
            <MenuItem value="">
              <em>Guest</em>
            </MenuItem>
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.first_name} {user.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="address-select-label">Shipping Address</InputLabel>
          <Select
            labelId="address-select-label"
            id="address-select"
            value={selectedAddress}
            label="Shipping Address"
            onChange={handleAddressChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {shippingAddresses.map((address) => (
              <MenuItem key={address.id} value={address.id}>
                {address.first_name} {address.last_name} - {address.city}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <DatePicker
          className="custom-datepicker"
          showIcon
          label="From Date"
          selected={fromDate}
          onChange={handleFromDateChange}
          dateFormat="dd/MM/yyyy"
          icon="fa fa-calendar"
          placeholderText="Select From Date"
          sx={{ minWidth: 200 }}
        />

        <DatePicker
          className="custom-datepicker"
          showIcon
          label="To Date"
          selected={toDate}
          onChange={handleToDateChange}
          dateFormat="dd/MM/yyyy"
          icon="fa fa-calendar"
          placeholderText="Select To Date"
        />

        <Button
          onClick={handleResetFilters}
          variant="contained"
          startIcon={<FilterListIcon />}
        >
          Reset Filters
        </Button>

        <Button
          onClick={handleExport}
          variant="contained"
          startIcon={<GetAppIcon />}
        >
          Export to Excel
        </Button>

        <Button
          onClick={() => handleDelete(selectedRows)}
          variant="contained"
          startIcon={<DeleteIcon />}
        >
          Delete Selected
        </Button>
      </Box>

      {loading ? (
        <div className="loader-container">
          <Loader type="spinner-cub" bgColor="gold" color="gold" size={100} />
        </div>
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          checkboxSelection
          onRowSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection);
          }}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              color: "#333",
              fontWeight: "bold",
              fontSize: "14px",
            },
            "& .MuiDataGrid-columnHeader": {
              borderRight: "1px solid #e0e0e0",
            },
            "& .MuiDataGrid-toolbarContainer": {
              backgroundColor: "grey",
            },
          }}
        />
      )}

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        sx={{ borderRadius: "10px" }}
      >
        <DialogContent>
          <DialogContentText
            style={{ fontSize: "1.5rem", paddingTop: "30px", color: "black" }}
          >
            Are you sure you want to delete the selected cart(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            gap: "40px",
          }}
        >
          <Button
            onClick={handleDeleteCancel}
            variant="contained"
            style={{
              color: "black",
              borderColor: "#ffd700",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirmation}
            variant="contained"
            style={{
              color: "black",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      <EditCartDialog
        isOpen={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        cartData={selectedcartData}
        shippingAddress={selectedcartShippingAddress}
        user={selectedcartUser}
      />
    </div>
  );
};

export default Carts;
