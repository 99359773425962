import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  IconButton,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import api from "state/api";
// import EditRewardDialog from "./EditRewardDialog";

const Rewards = () => {
  const [rewards, setRewards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState(null);
  const [deletingRewardId, setDeletingRewardId] = useState(null);

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        setLoading(true);
        const response = await api.getRewards();
        setRewards(response.rewards || []);
      } catch (error) {
        console.error("Error fetching rewards:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRewards();
  }, []);

  const handleEditReward = async (data) => {
    try {
      setLoading(true);
      await api.updateReward(data);
      const response = await api.getRewards();
      setRewards(response.rewards || []);
      setAlert({
        open: true,
        message: "Reward updated successfully!",
        severity: "success",
      });
      setEditDialogOpen(false);
    } catch (error) {
      console.error("Error updating reward:", error);
      setAlert({
        open: true,
        message: "Failed to update reward. Please try again.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteReward = async () => {
    try {
      setLoading(true);
      await api.removeReward(deletingRewardId);
      const response = await api.getRewards();
      setRewards(response.rewards || []);
      setAlert({
        open: true,
        message: "Reward deleted successfully!",
        severity: "success",
      });
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting reward:", error);
      setAlert({
        open: true,
        message: "Failed to delete reward. Please try again.",
        severity: "error",
      });
    } finally {
      setDeletingRewardId(null);
      setLoading(false);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Reward Name", width: 200 },
    { field: "probability", headerName: "Probability", width: 150 },
    { field: "max_occurrences", headerName: "Max Occurrences", width: 180 },
    {
      field: "current_occurrences",
      headerName: "Current Occurrences",
      width: 180,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            color="secondary"
            onClick={() => {
              setSelectedReward(params.row);
              setEditDialogOpen(true);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => {
              setDeletingRewardId(params.row.id);
              setDeleteDialogOpen(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div className="container">
      <DataGrid
        rows={rewards}
        columns={columns}
        loading={loading}
        checkboxSelection
        disableRowSelectionOnClick
        components={{ Toolbar: GridToolbar }}
      />

      {/* <EditRewardDialog
        isOpen={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        reward={selectedReward}
        onSubmit={handleEditReward}
      /> */}

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, open: false })}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogContent>
          <Typography>Are you sure you want to delete this reward?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteReward} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Rewards;
