import React, { useState, useEffect } from "react";
import "./products.css";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { red } from "@mui/material/colors";
import Loader from "react-js-loader"; // Assuming you have a Loader component
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Product = ({
  _id,
  name_en,
  description_en,
  price,
  discounted_price,
  rating,
  type_id,
  quantity,
  status,
  fetchAllProducts,
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [editProductData, setEditProductData] = useState(null);
  const [productTypes, setProductTypes] = useState([]);
  const statusOptions = ["Live", "Sold out", "Hidden", "Sale"];
  const [selectedProductType, setSelectedProductType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [initialProductData, setInitialProductData] = useState(null);

  useEffect(() => {
    fetch("https://dashboard.natindamiddleeast.com/api/getAllTypeIdAdmin")
      .then((response) => response.json())
      .then((data) => setProductTypes(data))
      .catch((error) => console.error("Error fetching product types:", error));
  }, []);

  const handleEditClick = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://dashboard.natindamiddleeast.com/api/getProductAdmin?id=${_id}`
      );
      const data = await response.json();
      setInitialProductData(data.product);

      setEditProductData(data.product);
      setSelectedProductType(data.product.product_type.name_en);
      setSelectedStatus(data.product.status);
      setEditDialogOpen(true);
    } catch (error) {
      console.error("Error fetching product data for edit:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);

    try {
      // Extract the fields that have been modified
      const updatedFields = {};
      for (const key in editProductData) {
        if (editProductData[key] !== initialProductData[key]) {
          updatedFields[key] = editProductData[key];
        }
      }

      if (Object.keys(updatedFields).length === 0) {
        console.log("No changes to save.");
        setEditDialogOpen(false);
        setIsLoading(false);
        return;
      }

      // Add any additional fields needed for the API
      updatedFields.status = selectedStatus;
      updatedFields.type_name_en = selectedProductType;

      const response = await fetch(
        `https://dashboard.natindamiddleeast.com/api/editProductAdmin?id=${_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedFields),
        }
      );

      if (!response.ok) {
        console.error("Error updating product:", response);
      }

      setEditDialogOpen(false);
      fetchAllProducts();
    } catch (error) {
      console.error("Error updating product:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };

  const handleDropdownChange = (selected, dropdownType) => {
    if (dropdownType === "productType") {
      setSelectedProductType(selected);
    } else if (dropdownType === "status") {
      setSelectedStatus(selected);
    }
  };

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
        "& > .MuiCardActions-root": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px",
        },
        "& > .MuiCardContent-root": {
          "& > .MuiTypography-root": {
            marginBottom: "1.5rem",
            color: theme.palette.secondary[400],
          },
        },
      }}
    >
      <CardActions>
        <Typography
          sx={{
            fontSize: 14,
            marginLeft: "0.5rem",
            marginRight: "auto",
          }}
          color={theme.palette.secondary[700]}
          gutterBottom
        >
          {type_id}
        </Typography>
        <Box>
          <IconButton size="small" onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        </Box>
      </CardActions>

      <Typography
        sx={{
          fontSize: 12,
          marginLeft: "1.5rem",
          marginRight: "auto",
          mb: "0.25rem",
          color: red[500],
        }}
        gutterBottom
      >
        {status}
      </Typography>
      <Typography
        variant="h5"
        component="div"
        sx={{ marginLeft: "0.75rem", marginRight: "0.75rem" }}
      >
        {name_en}
      </Typography>
      <CardContent>
        <Typography
          sx={{
            mb: "1.5rem",
          }}
          color={theme.palette.secondary[400]}
        >
          Normal Price: {Number(price).toFixed(2)} SAR
        </Typography>

        <Typography
          sx={{
            mb: "1.5rem",
          }}
          color={theme.palette.secondary[400]}
        >
          Sale Price: {Number(discounted_price).toFixed(2)} SAR
        </Typography>
        <Rating value={rating} readOnly />
        <Typography variant="body2">Stock Left: {quantity}</Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="primary"
          size="small"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          See More
        </Button>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          <Typography>id: {_id}</Typography>
          <Typography>Description: {description_en}</Typography>
        </CardContent>
      </Collapse>
      <Dialog
        open={isEditDialogOpen}
        onClose={handleEditClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent dividers>
          {isLoading ? (
            <div className="item">
              <Loader
                type="spinner-cub"
                bgColor="gold"
                color="gold"
                size={100}
              />
            </div>
          ) : (
            editProductData && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  Basic Information
                </Typography>
                <TextField
                  label="Product Name EN"
                  defaultValue={editProductData.name_en}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  onChange={(e) => {
                    setEditProductData((prevData) => ({
                      ...prevData,
                      name_en: e.target.value,
                    }));
                  }}
                />
                <TextField
                  label="Price"
                  defaultValue={editProductData.price}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  onChange={(e) => {
                    setEditProductData((prevData) => ({
                      ...prevData,
                      price: e.target.value,
                    }));
                  }}
                />
                <TextField
                  label="Discounted Price"
                  defaultValue={editProductData.discounted_price}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  onChange={(e) => {
                    setEditProductData((prevData) => ({
                      ...prevData,
                      discounted_price: e.target.value,
                    }));
                  }}
                />
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={selectedStatus}
                    onChange={(e) =>
                      handleDropdownChange(e.target.value, "status")
                    }
                    label="Select Status"
                  >
                    {statusOptions.map((status, i) => (
                      <MenuItem key={i} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Quantity"
                  defaultValue={editProductData.quantity}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  onChange={(e) => {
                    setEditProductData((prevData) => ({
                      ...prevData,
                      quantity: e.target.value,
                    }));
                  }}
                />
                <TextField
                  label="Size EN"
                  defaultValue={editProductData.size_en}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  onChange={(e) => {
                    setEditProductData((prevData) => ({
                      ...prevData,
                      size_en: e.target.value,
                    }));
                  }}
                />
                <TextField
                  label="Material EN"
                  defaultValue={editProductData.material_en}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  onChange={(e) => {
                    setEditProductData((prevData) => ({
                      ...prevData,
                      material_en: e.target.value,
                    }));
                  }}
                />

                <Typography variant="h6" gutterBottom>
                  Descriptions
                </Typography>
                <TextField
                  label="Description EN"
                  defaultValue={editProductData.description_en}
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  variant="outlined"
                  onChange={(e) => {
                    setEditProductData((prevData) => ({
                      ...prevData,
                      description_en: e.target.value,
                    }));
                  }}
                />

                <TextField
                  label="Details EN"
                  defaultValue={editProductData.details_en}
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  variant="outlined"
                  onChange={(e) => {
                    setEditProductData((prevData) => ({
                      ...prevData,
                      details_en: e.target.value,
                    }));
                  }}
                />

                <Typography variant="h6" gutterBottom>
                  Usage Instructions
                </Typography>
                <ReactQuill
                  theme="snow"
                  value={editProductData.how_to_use_en}
                  onChange={(value) =>
                    setEditProductData((prevData) => ({
                      ...prevData,
                      how_to_use_en: value,
                    }))
                  }
                />

                <Typography variant="h6" gutterBottom>
                  Ingredients
                </Typography>
                <TextField
                  label="Ingredients EN"
                  defaultValue={editProductData.ingredients_en}
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  variant="outlined"
                  onChange={(e) => {
                    setEditProductData((prevData) => ({
                      ...prevData,
                      ingredients_en: e.target.value,
                    }));
                  }}
                />

                <Typography variant="h6" gutterBottom>
                  Extra Details
                </Typography>
                <ReactQuill
                  theme="snow"
                  value={editProductData.why_we_love_it_en}
                  onChange={(value) =>
                    setEditProductData((prevData) => ({
                      ...prevData,
                      why_we_love_it_en: value,
                    }))
                  }
                />
                <ReactQuill
                  theme="snow"
                  value={editProductData.recommended_for_en}
                  onChange={(value) =>
                    setEditProductData((prevData) => ({
                      ...prevData,
                      recommended_for_en: value,
                    }))
                  }
                />
              </Box>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSaveChanges}
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Save Changes"}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default Product;
